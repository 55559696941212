import classnames from 'classnames';
import { memo } from 'react';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';

import { trackEvent } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { LoginLink, SignupLink } from 'lib/links';

import styles from './AuthMenu.module.scss';

import PrimaryButton from '../../Buttons/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton';

export type AuthButtonsProps = AuthButtonProps & {
  className?: string;
};

export type AuthButtonProps = {
  buttonClassName?: string;
  redirectUrl?: string | undefined;
};

const AuthButtons = ({ className, ...rest }: AuthButtonsProps) => (
  <div className={className}>
    <SignupButton {...rest} />
    <LoginButton {...rest} />
  </div>
);

export const SignupButton = ({
  buttonClassName,
  redirectUrl,
}: AuthButtonProps) => {
  const openSignupScreen = () => {
    trackEvent(AnalyticsEvent.AUTH_SIGNUP_BUTTON_CLICKED);
  };
  return (
    <SignupLink redirectUrl={redirectUrl}>
      <PrimaryButton
        className={classnames(styles.signupButton, buttonClassName)}
        onClick={openSignupScreen}
        size={BUTTON_SIZE.LARGE}
        text="Sign Up"
      />
    </SignupLink>
  );
};

export const LoginButton = ({
  buttonClassName,
  redirectUrl,
}: AuthButtonProps) => {
  const openLoginScreen = () => {
    trackEvent(AnalyticsEvent.AUTH_LOGIN_BUTTON_CLICKED);
  };
  return (
    <LoginLink redirectUrl={redirectUrl}>
      <SecondaryButton
        className={classnames(styles.loginButton, buttonClassName)}
        onClick={openLoginScreen}
        size={BUTTON_SIZE.LARGE}
        text="Log In"
      />
    </LoginLink>
  );
};

export default memo(AuthButtons);
