import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

import { getAuthModalHeader } from './utils';
import Modal, { ModalProps } from 'components/Modals/Modal/Modal';
import AuthButtons from 'components/NavigationMenu/mobile/AuthButtons';

import styles from './AuthModal.module.scss';

export enum ScreenTypes {
  Login,
  SignUp,
}

type AuthModalProps = Omit<ModalProps, 'children'> & {
  isOpen: boolean;
  /** tracking source for analytics */
  source?: string;
  startingScreen?: ScreenTypes;
};

const AuthModal = ({
  isOpen,
  source,
  startingScreen,
  ...rest
}: AuthModalProps): ReactElement => {
  const { asPath } = useRouter();

  const [screenType, setScreenType] = useState(
    startingScreen ?? ScreenTypes.Login
  );

  useEffect(() => {
    if (startingScreen !== undefined) {
      setScreenType(startingScreen);
    }
  }, [startingScreen]);

  const isLoginScreen = screenType === ScreenTypes.Login;

  const headerText = getAuthModalHeader(isLoginScreen, source);

  return (
    <Modal
      className={styles.root}
      header={headerText}
      headerClassname={styles.headerText}
      isOpen={isOpen}
      isUsingWidthOverride
      {...rest}
    >
      <AuthButtons buttonClassName={styles.authButton} redirectUrl={asPath} />
    </Modal>
  );
};

export default AuthModal;
